<template>
    <van-collapse v-model="activeNames">
        <van-collapse-item title="基础信息" name="1">
            <div class="perInfoBox">
                <van-image width="122" height="166" :src="detailForm.attachment1.length>0?imgUrl + detailForm.attachment1[0].document_path:''" />
                <div class="perInfoBase">
                   <div class="perInfoBaseTti">姓名：{{ detailForm.name }}</div>
                   <div class="perInfoBaseTti">性别：{{ detailForm.sex }} </div>
                   <div class="perInfoBaseTti">年龄： {{ detailForm.age}} </div>
                   <div class="perInfoBaseTti">身份证：{{ detailForm.idcode }}</div>
                   <div class="perInfoBaseTti">出生日期：{{ detailForm.birthday }}</div>
                </div>
            </div>
            <van-cell-group>
                <!-- <van-cell title="姓名" :value="detailForm.name" />
                <van-cell title="身份证号" :value="detailForm.idcode" />
                <van-cell title="性别" :value="detailForm.sex" />
                <van-cell title="出生日期" :value="detailForm.birthday" />
                <van-cell title="年龄" :value="detailForm.age" /> -->
                <van-cell title="化工从业日期" :value="detailForm.experience_start_date" />
                <van-cell title="工种" :value="detailForm.profession" />
                <van-cell title="是否承包商安全管理人员" :value="detailForm.is_safe_charger" />
                <van-cell title="是否承包商项目负责人" :value="detailForm.is_technique_charger" />
                <van-cell title="联系电话" :value="detailForm.person_tel" />
                <van-cell title="联系邮箱" :value="detailForm.person_email" />
                <van-cell title="是否承包商技术负责人" :value="detailForm.is_true_technique_charger" />
                <van-cell title="暂住地址" :value="detailForm.temporary_residence" />
                <van-cell title="紧急联系人" :value="detailForm.emergency_contact" />
                <van-cell title="与该人员关系" :value="detailForm.emergency_contact_rel" />
                <van-cell title="紧急联系人联系方式" :value="detailForm.emergency_contact_phone" />
            </van-cell-group>
        </van-collapse-item>
        <van-collapse-item title="资质信息" name="2" v-if="detailForm.CT_OPERATOR_INFO_FILE && detailForm.CT_OPERATOR_INFO_FILE.length > 0">
            <div class="operatorBox">
                <div class="operaorFileBox" v-for="(items,index) in detailForm.CT_OPERATOR_INFO_FILE" :key="index">
                    <div class="operaorFileTypes">{{findName(items.types)}}({{ items.code }})</div>
                    <van-tag v-if="items.expired_date && items.expired_date!='' && items.attachment1.length > 0" :type="!items.expired_date ||items.expired_date=='' ||items.attachment1.length == 0?'info':items.is_long_period==1?'success':new Date(items.expired_date+' 23:59:59') > new Date()?'success':'danger'" >{{!items.expired_date ||items.expired_date=='' ||items.attachment1.length == 0?'':items.is_long_period==1?'正常':new Date(items.expired_date+' 23:59:59') > new Date()?'正常':'过期'}}</van-tag>
                    <div class="operaorFileDate">有效期：<span :style="{'color':new Date(items.expired_date+' 23:59:59') > new Date() ||items.is_long_period==1?'':'#ff0000'}">{{ items.attachment1.length== 0?'':items.is_long_period==1?'长期':items.expired_date }}</span></div>
                    <div class="operaorFileOther">
                        <van-tag type="primary"  v-for="(item,index) in items.attachment1" :key="index" @click="global.downloadFile(item.document_path,item.name)">{{items.name}}</van-tag>
                    </div>

                </div>
            </div>
        </van-collapse-item>
        <van-collapse-item title="培训及授权情况" name="3" >
            <van-cell-group>
                <van-cell title="是否具备入场授权" :value="detailForm.is_accomplished_induction_training" />
                <van-cell title="授权角色" :value="detailForm.role_name" />
            </van-cell-group>
            <div class="operatorBox" v-if="detailForm.list_ct_train && detailForm.list_ct_train.length > 0">
                <div class="operaorFileBox" v-for="(items,index) in detailForm.list_ct_train" :key="index">
                    <div class="operaorFileTypes">{{ items.types1 }}</div>
                    <div class="operaorFileDate">子类型：{{ items.types_2}}</div>
                    <div class="operaorFileDate">培训通过时间：{{ items.train_end_date}}</div>
                    <div class="operaorFileDate">培训有效时间：{{ items.expired_date}}</div>

                </div>
            </div>
        </van-collapse-item>
        <van-collapse-item title="违规情况" name="4">
            <van-cell-group>
                <van-cell title="是否违规关卡期间" :value="detailForm.is_violation_level" />
                <van-cell title="是否取消授权期间" :value="detailForm.is_revoke_authorize" />
                <van-cell title="是否黑名单" :value="detailForm.is_blacklist" />
            </van-cell-group>
            <div class="operatorBox" v-if="detailForm.list_ct_violation_case && detailForm.list_ct_violation_case.length > 0">
                <div class="operaorFileBox" v-for="(items,index) in detailForm.list_ct_violation_case" :key="index">
                    <div class="operaorFileTypes">{{ items.violation_date }}</div>
                    <div class="operaorFileDate">违规级别：{{ items.ct_violation_level_name}}</div>
                    <div class="operaorFileDate">{{ items.violation_description}}</div>
                </div>
            </div>
        </van-collapse-item>
        <van-collapse-item title="奖励情况" name="5" v-if="detailForm.list_ct_reward && detailForm.list_ct_reward.length > 0">
            <div class="operatorBox" >
                <div class="operaorFileBox" v-for="(items,index) in detailForm.list_ct_reward" :key="index">
                    <div class="operaorFileTypes">{{ items.types_name }}</div>
                    <div class="operaorFileDate">奖励时间：{{ items.reward_date}}</div>
                    <div class="operaorFileDate">奖励金额：{{ items.reward}}</div>
                </div>
            </div>
        </van-collapse-item>
    </van-collapse>
</template>
<script>

import { reactive, watch, onMounted, ref } from "vue";
import http from "../../../api/http";
import { useRoute } from 'vue-router';
export default{
    setup() {
        const route = useRoute();
        const imgUrl = ref(http.getImgUrl());
        const activeNames = ref(['1','2','3','4','5'])
        const options = reactive({
            default_file_types:[],
            roleList:[]
        })
        const detailForm = reactive({
            is_accomplished_induction_training:'',
            is_violation_level:'',
            is_revoke_authorize:'',
            is_blacklist:'',
            is_long_period:"",
            role_name:[],
            name:'',
            is_true_technique_charger:"",
            idcode:'',
            sex:'',
            birthday:'',
            age:'',
            experience_start_date:'',
            profession:[],
            attachment1:[],
            is_safe_charger:'',
            is_technique_charger:'',
            change_status:'',
            person_tel:'',
            person_email:'',
            CT_OPERATOR_INFO_FILE_APPLICATION:[],
            CT_OPERATOR_INFO_FILE:[],
            list_ct_reward:[],
            list_ct_train:[],
            list_ct_violation_case:[],
            temporary_residence:'',
            emergency_contact:'',
            emergency_contact_phone:'',
            emergency_contact_rel:''
        });
        const getInfoDetail = () =>{
            http.get({
                method: "detail",
                t:'CT_OPERATOR_INFO',
                id:route.params.id
            })
            .then((res)=>{
                var mapResult = res.map;
                detailForm.is_accomplished_induction_training = mapResult.is_accomplished_induction_training == 1?"是":'否';
                detailForm.is_violation_level= mapResult.is_violation_level == 1?'是':'否';
                detailForm.is_revoke_authorize = mapResult.is_revoke_authorize == 1?'是':'否';
                detailForm.is_blacklist = mapResult.is_blacklist == 1?'是':'否';
                detailForm.is_long_period = mapResult.is_long_period == 1?'是':'否';
                detailForm.name = mapResult.name
                detailForm.is_true_technique_charger = mapResult.is_true_technique_charger == 1?'是':'否';
                detailForm.idcode = mapResult.idcode;
                detailForm.sex = mapResult.sex_name;
                detailForm.birthday = mapResult.birthday;
                detailForm.age = mapResult.age;
                detailForm.experience_start_date = mapResult.experience_start_date;
                detailForm.is_safe_charger = mapResult.is_safe_charger == 1?'是':'否';
                detailForm.is_technique_charger = mapResult.is_technique_charger == 1?'是':'否';
                detailForm.change_status = mapResult.change_status;
                detailForm.person_tel = mapResult.person_tel;
                detailForm.person_email = mapResult.person_email;
                detailForm.temporary_residence = mapResult.temporary_residence;
                detailForm.emergency_contact = mapResult.emergency_contact;
                detailForm.emergency_contact_phone = mapResult.emergency_contact_phone;
                detailForm.emergency_contact_rel = mapResult.emergency_contact_rel;
                detailForm.CT_OPERATOR_INFO_FILE = mapResult.CT_OPERATOR_INFO_FILE;
                detailForm.list_ct_reward  = mapResult.list_ct_reward
                detailForm.list_ct_train = mapResult.list_ct_train
                detailForm.list_ct_violation_case  = mapResult.list_ct_violation_case
                detailForm.profession = mapResult.profession_name;
                detailForm.attachment1 = mapResult.attachment1
                detailForm.role_name = getRoleName(mapResult.role_name);
            })
        }
        const getRoleName = (roleList) =>{
            var list = [];
            if(roleList && roleList.length > 0){
                roleList.forEach(element => {
                    var obj = options.role_name.find(item=>item.value == element);
                    if(obj){
                        list.push(obj.label)
                    }
                });
            }
            return list.join(',');
        }
        const getSelectList = () =>{
            http.get({
                method:'listMultiPardata',
                t:'CT_OPERATOR_INFO',
                prop:'role_name'
            },true)
            .then(res=>{
                options.role_name = res.resultData.role_name;
            })
            http.get({
                method:'listMultiPardata',
                t:'CT_OPERATOR_INFO_FILE',
                prop:'types'
            },true)
            .then(res=>{
                options.default_file_types = res.resultData.types;
            })
        }
        const findName = (key) =>{
            var name = '';
            var keyObj = options.default_file_types.find(items=>items.value == key);
            if(keyObj && keyObj!=undefined &&keyObj!=null){
                name = keyObj.label;
            }
            return name;
        }

        onMounted(()=>{
            getSelectList();
            getInfoDetail();
        });
        return {
            activeNames,
            route,
            detailForm,
            imgUrl,
            findName
        }
    }
}
</script>
